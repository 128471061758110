<template>
    <b-card title="Création de pays">
        <b-form>
            <b-form-group id="input-group-1"
                          label="Code :"
                          label-for="input-1">
                <b-form-input id="input-1"
                              type="text"
                              placeholder="Code"
                              v-model="country.id"
                              @change="searchCInsert($event)"
                              required></b-form-input>
              <div v-if="formErrorCode" class="text-danger">
                Le code du pays existe déjà
              </div>
            </b-form-group>
            <b-form-group id="input-group-2"
                          label="Nom :"
                          label-for="input-2">
              <b-form-input id="input-2"
                            type="text"
                            placeholder="Nom"
                            v-model="country.name"
                            @change="searchCInsertByName($event)"
                            required></b-form-input>
              <div v-if="formErrorName" class="text-danger">
                Le nom du pays existe déjà
              </div>
            </b-form-group>
            <b-form-group id="input-group-3" label="Filtre :" label-for="input-3">
                <b-form-select id="input-3"
                               :options="selectOptions"
                               v-model="selectedOption" @change="setCountryInsertList(true)"></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-3" label="Pays :" label-for="input-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input"
                              v-model="filterValue"
                              placeholder="Nom du Pays" @change="filter = filterValue"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                  <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-pagination v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            aria-controls="countryInsertsList" ></b-pagination>
              <b-table :items="countryInsertsList" :fields="countryInsertsFields" head-variant="light" sticky-header="600px" :per-page="perPage"
                       :current-page="currentPage"
                       :filter="filter"
                       :filter-included-fields="filterOn"
                       :sort-by.sync="sortBy"
                       :busy="refreshTable"
                       tbody-tr-class="trclass"  @filtered="onFiltered"
              >
                <template #cell(id)="data">
                  <b-icon-check-circle v-if="selectedCountryInsertsItems[data.value] == 1" class="icon-span" @click="selectedCountryInsertsItemClick(data.value);selectedCountryInsertsUpdate()" />
                  <b-icon-circle v-if="selectedCountryInsertsItems[data.value] != 1" class="icon-span" @click="selectedCountryInsertsItemClick(data.value);selectedCountryInsertsUpdate()" />
                </template>
                <template #cell(name)="data">
                  <span class="text-capitalize">{{ data.value }}</span>
                </template>
              </b-table>
            </b-form-group>
            <b-button type="button" :disabled="formErrorCode || formErrorName" variant="primary" @click="postform">Submit</b-button>
            <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'

    export default {
        name: 'CreateCountry',
        components: {
        },
        data() {
            return {
                formErrorCode: false,
                formErrorName: false,
                refreshTable: true,
                countryInsertsList: [],
                selectedCountryInsertsItems: {},
                countryInsertInsertCleans: [],
                countryInsertsById: {},
                filterValue: null,
                filter: null,
                sortBy: 'name',
                filterOn: ['name'],
                perPage: 8,
                currentPage: 1,
                countRows: -1,
                countryInsertsFields: [
                  {
                    key: 'id',
                    label: 'Selection',
                    thStyle: { width: "8%" },
                  },
                  {
                    key: 'name',
                    label: 'Pays',
                    thStyle: { width: "62%" },
                  }
                ],
                selectedCountryInserts: [],
                listOptions: [],
                selectedOption: 'empty',
                country: {},
                selectOptions: [
                    { value: "all", text: "Tous les pays" },
                    { value: "empty", text: "Pays non affectés" },
                    { value: "groups", text: "Pays déjà affectés uniquement" }
                ]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryInsertInsertToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.name) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        event: ['reset', 'save'],
        methods: {
            selectedCountryInsertsItemClick : function (key) {
              if (this.selectedCountryInsertsItems[key] !== undefined) {
                this.selectedCountryInsertsItems[key] = this.selectedCountryInsertsItems[key] == 0 ? 1 : 0;
                return true
              }
              return false
            },
            selectedCountryInsertsAdd : function (key) {
              if (this.selectedCountryInsertsItems[key] === undefined) {
                this.selectedCountryInsertsItems[key] = 0;
                return true
              }
              return false
            },
            selectedCountryInsertsUpdate : function () {
              this.selectedCountryInserts = this.selectedCountryInsertsCpt();
              this.setCountryInsertList(false)
            },
            selectedCountryInsertsReset : function() {
              this.selectedCountryInserts = this.selectedCountryInsertsCpt();
              this.selectedCountryInsertsItems = {}
              this.selectedCountryInserts.forEach((key) => {
                this.selectedCountryInsertsItems[key] = 1;
              });
              return this.selectedCountryInserts;
            },
            selectedCountryInsertsCpt : function () {
              return Object.keys(this.selectedCountryInsertsItems).map((key) =>
                  {
                    if (this.selectedCountryInsertsItems[key] )
                    {
                      return key;
                    }
                    return null;
                  }
              ).filter((val) => (val !== undefined && val !== null));
            },
            resetform: function () {
                this.$emit('reset')
            },
            postform: function () {
                let objPost = {
                    id: this.country.id,
                    name: this.country.name,
                    addCountryInserts: this.selectedCountryInsertsCpt(),
                    removeCountryInserts: [],
                }
                console.log(JSON.stringify(objPost))
                let self = this
                axios.put(this.$ipService + '/api/Countries/', objPost
                ).then(function () {
                  self.$store.dispatch('loadCountryInserts')
                  self.$store.dispatch('loadCountries')
                  self.$store.dispatch('loadCountriesActives')
                  self.$emit('reset')
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {

                })
            },
            getEmptyList: function () {
              let result = []
              this.selectedCountryInserts = this.selectedCountryInsertsReset();

              this.countryInserts.forEach(ctry => {
                  if (ctry?.countryCodes === undefined) {
                    this.selectedCountryInsertsAdd(ctry.id)
                    return
                  }
                  if (ctry.countryCodes.length == 0) {
                      this.selectedCountryInsertsAdd(ctry.id)
                  }
                })
                this.selectedCountryInserts.forEach(
                    (id) => {
                      if (this.countryInsertsById[id] === undefined) {
                        return
                      }
                      let ctry = { id: id,  name: this.countryInsertsById[id].name }
                      this.selectedCountryInsertsAdd(ctry.id)
                    }
                )
                result =  Object.keys(this.selectedCountryInsertsItems).map((id) => {
                  return { id: id,  name: this.countryInsertsById[id].name }
                })
                return result
            },
            getAllList: function () {
                this.selectedCountryInserts = this.selectedCountryInsertsReset();
                let result = [];
                this.countryInserts.forEach(ctry => {
                    this.selectedCountryInsertsAdd(ctry.id)
                    result.push({ id: ctry.id,  name: ctry.name })
                })
                result =  Object.keys(this.selectedCountryInsertsItems).map((id) => {
                  return { id: id,  name: this.countryInsertsById[id].name }
                })
                return result;
            },
            getGroupList: function () {
                this.selectedCountryInserts = this.selectedCountryInsertsReset();
                let result = []
                if (this.country?.countryInserts === undefined) {
                  return []
                }
                this.selectedCountryInserts.forEach(
                    (id) => {
                      if (this.countryInsertsById[id] === undefined) {
                        return
                      }
                      let ctry = { id: id,  name: this.countryInsertsById[id].name }
                      this.selectedCountryInsertsAdd(ctry.id)
                      result.push(ctry);
                    }
                )
                result =  Object.keys(this.selectedCountryInsertsItems).map((id) => {
                  return { id: id,  name: this.countryInsertsById[id].name }
                })
                return result
            },
            getGroupsList: function () {
                this.selectedCountryInserts = this.selectedCountryInsertsReset();
                let result = []
                this.countryInserts.forEach(ctry => {
                    if (ctry?.countryCodes === undefined) {
                      return
                    }
                    if (ctry.countryCodes.length > 0) {
                        this.selectedCountryInsertsAdd(ctry.id)
                        result.push({ id: ctry.id,  name: ctry.name })
                    }
                })

                result =  Object.keys(this.selectedCountryInsertsItems).map((id) => {
                  return { id: id,  name: this.countryInsertsById[id].name }
                })
                return result
            },
            onFiltered: function(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              if (this.countryInsertsList == undefined || this.countryInsertsList == null) {
                this.countRows = -1
                this.currentPage = 1
                return
              }
              this.countRows = filteredItems.length
              this.currentPage = 1
            },
            setCountryInsertList: function (resetValues = true){
              this.refreshTable = true
              if (resetValues) {
                this.currentPage = 1
                this.filterValue = ''
                this.filter = ''
                this.countRows = -1
              }
              this.countryInsertsList = [];
              if (this.selectedOption == "all") {
                this.countryInsertsList = this.getAllList()
              } else if (this.selectedOption == "empty") {
                this.countryInsertsList = this.getEmptyList()
              } else {
                this.countryInsertsList = this.getGroupsList()
              }
              this.countRows = this.countryInsertsList.length
              this.refreshTable = false
            },
            searchCInsert: function (value) {
              let self = this
              this.formErrorCode = true
              axios.get(this.$ipService + '/api/Countries/' + value + '?date=' + new Date().getTime()
              ).then(function () {
                self.formError = true;
              }).catch(function () {
                self.formErrorCode = false;
              });
            },
            searchCInsertByName: function (value) {
              let self = this
              this.formErrorName = true
              axios.get(this.$ipService + '/api/Countries/SearchByName/' + value + '?date=' + new Date().getTime()
              ).then(function (response) {
                self.formErrorName = response.data.length > 0
              });
            }

        },
        computed: {
            totalRows: function () {
              if (this.countRows > 0) {
                return this.countRows
              }
              return this.countryInsertsList.length
            },
            ...mapState(["countries", "countryInserts", "currentUser"])
        },
        mounted: function () {
            this.country = {id: '', name: '', countryInserts: []}
            this.countryInsertsById = {}
            this.countryInserts.forEach((countryInsert) => {
              this.countryInsertsById[countryInsert.id] = countryInsert
            })
            this.countryInsertsList = this.getEmptyList()
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }
</style>
