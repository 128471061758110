import { render, staticRenderFns } from "./Variations.vue?vue&type=template&id=4550dd4c&scoped=true"
import script from "./Variations.vue?vue&type=script&lang=js"
export * from "./Variations.vue?vue&type=script&lang=js"
import style0 from "./Variations.vue?vue&type=style&index=0&id=4550dd4c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4550dd4c",
  null
  
)

export default component.exports