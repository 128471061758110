<template>
    <div class="currpage">
        <b-jumbotron id="catalogFilterArea" class="container">
            <div class="form-group row">
                <div class="form-title col2" style="text-align: right; padding-top: 10px"><label class="form-label">Accès direct&nbsp;:&nbsp;</label></div>
                <div class="col2">
                    <cool-select v-model="codeSearch" :items="lGroupsOrProducs" item-value="key" item-text="name"  v-on:keyup.enter="formSearch()" 
                                    disable-filtering-by-search @search="onSearchGroupsOrProducs">
                        <template slot="item" slot-scope="{ item: groupOrProduct }">
                            <div style="display: flex; align-items: center;">
                                <div>
                                    <b>{{ groupOrProduct.name }} ({{ groupOrProduct.strType }})</b>
                                </div>
                            </div>
                        </template>
                    </cool-select>
                </div>
                <div class="col" style="padding-left: 0px;">
                    <b-button @click="formSearch()">Rechercher</b-button>
                </div>
            </div>
            <div class="form-group row form-title">
                <div class="form-title col">Sites&nbsp;:&nbsp;</div>
            </div>
            <div class="form-group row">
                <div class="col">
                    <div v-for="website in activesWebSites" :key="website.id" class="form-check form-check-inline">
                        <b-form-checkbox switch class="form-check-input" :id="'inlineCheckbox'+website.id" :value="website.id" @change="websitesChanges" v-model="websitesChk">
                            {{ website.name | capitalize }}
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
            <div class="form-group row form-title">
                <div class="col">
                    <label class="form-check-label">Dur&eacute;e entre :</label>
                </div>
                <div class="col">
                    <label class="form-check-label">Et :</label>
                </div>
                <div class="col">
                    <label class="form-check-label">&nbsp;</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="form-check col">
                    <b-form-select v-model="durationMin">
                        <b-form-select-option :value="null">----</b-form-select-option>
                        <b-form-select-option v-for="gDuration in groupDurations" :key="gDuration.id" :value="gDuration.id">
                            {{ gDuration.label }}
                            <span v-if="gDuration.min == 0">( jusqu'à {{gDuration.max}} jours)</span>
                            <span v-if="gDuration.min > 0 && gDuration.max > 0">( de {{gDuration.min}} à {{gDuration.max}} jours)</span>
                            <span v-if="gDuration.max == 0">( au moins {{gDuration.min}} jours)</span>
                        </b-form-select-option>
                    </b-form-select>
                </div>
                <div class="form-check col">
                    <b-form-select v-model="durationMax">
                        <b-form-select-option :value="null">----</b-form-select-option>
                        <b-form-select-option v-for="gDuration in groupDurations" :key="gDuration.id" :value="gDuration.id">
                            {{ gDuration.label }}
                            <span v-if="gDuration.min == 0">( jusqu'à {{gDuration.max}} jours)</span>
                            <span v-if="gDuration.min > 0 && gDuration.max > 0">( de {{gDuration.min}} à {{gDuration.max}} jours)</span>
                            <span v-if="gDuration.max == 0">( au moins {{gDuration.min}} jours)</span>
                        </b-form-select-option>
                    </b-form-select>
                </div>
                <div class="form-check col">
                    <b-form-checkbox switch class="form-check-input" id="garanti" v-model="dateGaranti">
                        Départs garantis uniquement
                    </b-form-checkbox>
                </div>
            </div>
            <div class="form-group row">
                <div class="col form-title">
                    <label class="form-check-label">Zone G&eacute;o : </label>
                </div>
                <div class="col">
                    <label class="form-check-label">Pays : </label>
                </div>
                <div class="col">
                    <label class="form-check-label">Type : </label>
                </div>
                <div class="col">
                    <label class="form-check-label">Niveau : </label>
                </div>
            </div>
            <div class="form-group row">
                <div class="form-check col">
                    <div class="div-checkable">
                        <div v-for="gCountry in groupCountries" :key="gCountry.id" :value="gCountry.id">
                            <b-form-checkbox switch class="spaced" :id="'gCountry'+gCountry.id" :value="gCountry.id" @change="websitesChanges" v-model="gCountriesChk">{{ gCountry.label | capitalize }}</b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="form-check col">
                    <div class="div-checkable">
                        <div v-for="country in lcountries" :key="country.id" :value="country.id">
                            <b-form-checkbox switch class="spaced" type="checkbox" :id="'country'+country.id" :value="country.id" v-model="countriesChk">{{ country.name | capitalize }} ({{ country.nbProducts}})</b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="form-check col">
                    <div class="div-checkable">
                        <div v-for="gType in groupTypes" :key="gType.id" :value="gType.id">
                            <b-form-checkbox switch class="spaced" type="checkbox" :id="'gType'+gType.id" :value="gType.id" v-model="groupTypesChk">{{ gType.label | capitalize }}</b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="form-check col">
                    <div class="div-checkable">
                        <div v-for="gLevel in groupLevels" :key="gLevel.id" :value="gLevel.id">
                            <b-form-checkbox switch class="spaced" type="checkbox" :id="'gLevel'+gLevel.id" :value="gLevel.id" v-model="groupLevelsChk">{{ gLevel.label | capitalize }}</b-form-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row form-title">
                <div class="col">
                    <label class="form-check-label">Date de d&eacute;part entre : </label>
                </div>
                <div class="col">
                    <label class="form-check-label">Et : </label>
                </div>
                <div class="col">
                    <label class="form-check-label">Prix entre : </label>
                </div>
                <div class="col">
                    <label class="form-check-label">Et : </label>
                </div>
            </div>
            <div class="form-group row">
                <div class="form-check col">
                    <b-form-input v-model="dateFrom" type="date"></b-form-input>
                </div>
                <div class="form-check col">
                    <b-form-input v-model="dateTo" type="date"></b-form-input>
                </div>
                <div class="form-check col">
                    <b-form-input v-model="priceMin" type="number"></b-form-input>
                </div>
                <div class="form-check col">
                    <b-form-input v-model="priceMax" type="number"></b-form-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="form-button col">
                    <b-button variant="outline-primary" v-on:click="formFilter()">Filtrer</b-button>
                </div>
            </div>
            <div class="form-group row">
                <div class="form-button col">
                    <b-button variant="outline-primary" v-on:click="formDownload()">Export Excel</b-button>
                </div>
            </div>
        </b-jumbotron>

        <div class="data-result container">
            <b-table striped hover :items="products" :busy="loading" :fields="productsField" :sort-compare="sortCompare" head-variant="light" sticky-header="400px">
                <template #cell(id)="data">
                    <b-form-checkbox class="spaced" type="checkbox" :id="'compareProduct'+data.value" :value="data.value" v-model="compared"></b-form-checkbox>
                </template>

                <template #cell(groupProducts)="data">
                    <div v-for="group in data.value" :key="group.id" v-on:click="addGroupProduct(group, false, $event)" @contextmenu="addGroupProduct(group, true, $event)" class="clickable">{{group.label}}</div>
                </template>

                <template #cell(code)="data">
                    <span v-on:click="addProduct(data.item, false, $event)" @contextmenu="addProduct(data.item, true,  $event)" class="clickable">{{data.value}}</span>
                </template>
                <template #cell(countries)="data">
                    <img v-for="country in data.value" :key="country.name" :src="'/flags/'+country.id.toLowerCase().substr(0,2)+'.gif'" :alt="country.name" :title="country.name" />
                </template>
                <template #cell(groupTypes)="data">
                    <div v-for="type in data.value" :key="type.label">{{type.label}}</div>
                </template>
                <template #cell(groupLevels)="data">
                    <div v-for="level in data.value" :key="level.label">{{level.label}}</div>
                </template>
                <template #cell(firstDeparture)="data">
                    {{data.value | moment('DD/MM/YYYY')}}
                </template>
                <template #cell(lastReturn)="data">
                    {{data.value | moment('DD/MM/YYYY')}}
                </template>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
            </b-table>
            <div class="form-button"><b-button variant="primary" v-bind:disabled="compared.length == 0" v-on:click="addListProduct">Comparer</b-button></div>
        </div>
    </div>
</template>

<script scoped>
    import Vue from 'vue'
    import axios from 'axios'
    import { mapState } from "vuex"
    import { BJumbotron } from 'bootstrap-vue'
    import { CoolSelect } from 'vue-cool-select'
import { String } from 'core-js'
    Vue.component('b-jumbotron', BJumbotron)

    export default {
        components: { CoolSelect },
        name: 'Catalogue',
        data() {
            return {
                codeSearch: "",
                lGroupsOrProducs: [],
                compared: [],
                lcountries: [],
                gCountriesChk: [],
                websitesChk: [],
                countriesChk: [],
                groupTypesChk: [],
                groupLevelsChk: [],
                durationMin: null,
                durationMax: null,
                dateFrom: null,
                dateTo: null,
                priceMin: null,
                priceMax: null,
                products: [],
                loading: false,
                dateGaranti: false,
                items: ['Item 1', 'Item 2', 'Item 3'],
                productsField: [
                    {
                        key: 'id',
                        label: 'Comp'
                    },
                    {
                        key: 'groupProducts',
                        label: 'Groupe de produit',
                        sortable: true
                    },
                    {
                        key: 'code',
                        label: 'Produit',
                        sortable: true
                    },
                    {
                        key: 'website',
                        label: 'Site',
                        sortable: true
                    },
                    {
                        key: 'countries',
                        label: 'Country',
                        sortable: true
                    },
                    {
                        key: 'duration',
                        label: 'Durée',
                        sortable: true
                    },
                    {
                        key: 'groupTypes',
                        label: 'Type de produit',
                        sortable: true
                    },
                    {
                        key: 'groupLevels',
                        label: 'Niveau',
                        sortable: true
                    },
                    {
                        key: 'firstDeparture',
                        label: 'Premier départ',
                        sortable: true
                    },
                    {
                        label: 'Dernier retour',
                        key: 'lastReturn',
                        sortable: true
                    },
                    {
                        label: 'Prix Min',
                        key: 'minPrice',
                        sortable: true
                    },
                    {
                        key: 'maxPrice',
                        label: 'Prix Max',
                        sortable: true
                    },
                    {
                        key: 'totalDeparture',
                        label: 'Nb Départ',
                        sortable: true
                    },
                    {
                        key: 'totalGuaranteed',
                        label: 'Nb Garanti',
                        sortable: true
                    },
                    {
                        key: 'totalDiscount',
                        label: 'Nb Promo',
                        sortable: true
                    }
                ]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase() 

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            sortValues: function (values) {
                if (!values.length) return '';
                if (!Array.isArray(values)) return '';
                let valReturn = "";
                values.forEach(el => {
                    if (el.label != undefined) {
                        valReturn = valReturn + "-" + el.label
                    }
                    if (el.name != undefined) {
                        valReturn = valReturn + "-" + el.name
                    }
                })
                return valReturn
            }
        },
        props: {
        },
        methods: {
            onSearchGroupsOrProducs: function (search) {
                this.lGroupsOrProducs = []
                if (search.length < 1) return;
                let self = this
                this.groupsOrProducs.forEach(item => {
                    if (item.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                        item.key = "" + item.type + "#§#" + item.name
                        item.strType = item.typeValue;
                        if (item.type == 1) {
                            item.strType += " : " + item.website
                        }
                        self.lGroupsOrProducs.push(item)
                    }
                })
                self.lGroupsOrProducs.sort((x, y) => {
                    let nX = x.name.toLowerCase()
                    let nY = y.name.toLowerCase()
                    return nX.localeCompare(nY)
                })
            },
            addGroupProduct: function (group, showDetail, e) {
                e.preventDefault()
                this.$store.commit("addTabGroup", { id: group.id, name: group.label, type: "group", group: group })
                this.$store.commit("setCurrentDetail", group.label)
                if (showDetail) {
                    this.$store.commit('setCurrentTab', 'detail')
                }
            },
            addListProduct: function () {
                let groupId = this.createUUID()
                let compName = "Comp_" + groupId.substring(0,8);
                let products = [...this.compared]

                this.$store.commit("addTabGroup", { id: groupId, name: compName, type: "comparaison", products: products })
                this.$store.commit("setCurrentDetail", compName)
                this.compared = []
                this.$store.commit('setCurrentTab', 'detail')
            },
            addProduct: function (product, showDetail, e) {
                e.preventDefault()
                let groupId = 'PROD_' + product.id
                let compName = 'PROD_' + product.code

                this.$store.commit("addTabGroup", { id: groupId, name: compName, type: "comparaison", products: [product.id] })
                this.$store.commit("setCurrentDetail", compName)
                if (showDetail) {
                    this.$store.commit('setCurrentTab', 'detail')
                }
            },
            createUUID: function(){
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            websitesChanges: function () {
                let self = this

                setTimeout(function () {
                    self.lcountries = []
                    self.countriesChk = []
                    self.products = []
                    self.compared = []

                    let objTest = { GoupCountryIds: self.gCountriesChk, AdapterIds: self.websitesChk }
                    axios.post(self.$ipService + '/api/countries/filtered',
                        objTest
                    ).then(function (response) {
                        self.lcountries = response.data
                    }).catch(function (error) {
                        console.log(error);
                    })
                }, 100);
            },
            sortCompare: function(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
                const a = aRow[key] // or use Lodash `_.get()`
                const b = bRow[key]
                
                if (typeof a === 'object') {
                    let aStr = ''
                    let bStr = ''
                    for (let i = 0; i < a.length; i++) {
                        if (a[i].label === undefined) {
                            aStr += a[i].name
                        } else {
                            aStr += a[i].label
                        }
                    }
                    for (let i = 0; i < b.length; i++) {
                        if (b[i].label === undefined) {
                            bStr += b[i].name
                        } else {
                            bStr += b[i].label
                        }
                    }
                    return toString(aStr).localeCompare(toString(bStr), compareLocale, compareOptions)
                }
                if (
                    (typeof a === 'number' && typeof b === 'number') ||
                    (a instanceof Date && b instanceof Date)
                ) {
                    // If both compared fields are native numbers or both are native dates
                    return a < b ? -1 : a > b ? 1 : 0
                } else {
                    // Otherwise stringify the field data and use String.prototype.localeCompare
                    return toString(a).localeCompare(toString(b), compareLocale, compareOptions)
                }
            },
            formSearch: function () {
                this.loading = true;
                let self = this;
                let objQuery = {
                    AdapterIds: [],
                    GoupCountryIds: [],
                    CountriesIds: [],
                    GroupTypesIds: [],
                    GroupLevelsIds: [],
                    DurationMinId: null,
                    DurationMaxId: null,
                    DateFrom: null,
                    DateTo: null,
                    PriceMin: 0,
                    PriceMax: 0,
                    DateGaranti: false
                }
                let arrSrch = this.codeSearch.split("#§#")
                if (arrSrch[0] == "1") {
                    objQuery.ProductName = arrSrch[1]
                } else {
                    objQuery.GroupProductName = arrSrch[1]
                }
                axios.post(this.$ipService + '/api/products/filtered',
                    objQuery
                ).then(function (response) {
                    self.products = response.data
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    self.loading = false;
                })
            },
            formFilter: function () {
                this.loading = true;
                let self = this;
                let objQuery = {
                    AdapterIds: this.websitesChk,
                    GoupCountryIds: this.gCountriesChk,
                    CountriesIds: this.countriesChk,
                    GroupTypesIds: this.groupTypesChk,
                    GroupLevelsIds: this.groupLevelsChk,
                    DurationMinId: this.durationMin,
                    DurationMaxId: this.durationMax,
                    DateFrom: this.dateFrom,
                    DateTo: this.dateTo,
                    PriceMin: isNaN(this.priceMin) ? null : Math.round(this.priceMin),
                    PriceMax: isNaN(this.priceMax) ? null : Math.round(this.priceMax),
                    ProductName: null,
                    DateGaranti: this.dateGaranti
                }
                axios.post(this.$ipService + '/api/products/filtered',
                    objQuery
                ).then(function (response) {
                    self.products = response.data
                }).catch(function (error) {
                    console.log(error);
                }).finally( () => {
                    self.loading = false;
                })
            },
            formDownload: function () {
                this.loading = true;
                let self = this;
                let objQuery = {
                    AdapterIds: this.websitesChk,
                    GoupCountryIds: this.gCountriesChk,
                    CountriesIds: this.countriesChk,
                    GroupTypesIds: this.groupTypesChk,
                    GroupLevelsIds: this.groupLevelsChk,
                    DurationMinId: this.durationMin,
                    DurationMaxId: this.durationMax,
                    DateFrom: this.dateFrom,
                    DateTo: this.dateTo,
                    PriceMin: isNaN(this.priceMin) ? null : Math.round(this.priceMin),
                    PriceMax: isNaN(this.priceMax) ? null : Math.round(this.priceMax),
                    ProductName: null,
                    DateGaranti: this.dateGaranti
                }
                axios.post(this.$ipService + '/api/export/export',
                    objQuery, { responseType: 'blob' }
                ).then(function (response) {
                    const blob = new Blob([response.data], { type: 'application/xlsx' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'export.xlsx'
                    link.click()
                    link.remove()
                    URL.revokeObjectURL(link.href)
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    self.loading = false;
                })
            }
        },
        watch : {
            activesWebSites: function () {
              this.websitesChanges()
            },
            groupDurations: function () {
              this.websitesChanges()
            },
            countries: function () {
                this.websitesChanges()
              },
            groupCountries: function () {
              this.websitesChanges()
            },
            groupTypes: function () {
                this.websitesChanges()
            },
            groupsOrProducs: function () {
              this.websitesChanges()
            },
        },
        computed: {
            ...mapState(["tabsgroup", "webSites", "activesWebSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "groupsOrProducs"])
        },
        mounted: function () {

            this.activesWebSites.forEach(website => {
                this.websitesChk.push(website.id)
            })

            this.websitesChanges()
        }
    };

    // eslint-disable-next-line no-unused-vars
    

    function toString(value) {
        if (value === null || typeof value === 'undefined') {
            return ''
        } else if (value instanceof Object) {
            return Object.keys(value)
                .sort()
                .map(key => toString(value[key]))
                .join(' ')
        } else {
            return String(value)
        }
    }
</script>

<style scoped>


    #catalogFilterArea {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .currpage label {
        text-align: right !important
    }

    .div-checkable {
        margin-bottom: 5px;
        border-color: #AAAAAA;
        border-style: solid;
        border-width: 1px;
        float: left;
        min-width: 100px;
        width: 80%;
        height: 100px;
        margin-left: 0;
        overflow-y: auto;
        font-size: 1em;
        vertical-align: middle;
    }



    .currpage .container {
        max-width: 2000px;
    }

    .currpage input[type=checkbox].spaced {
        margin: 0.1em 0.3em 0 0.1em;
    }

    .currpage div.form-title {
        font-weight: bold;
        margin-bottom: 0px;
    }

    .currpage div.form-button {
        text-align: right;
    }

    .currpage .clickable {
        cursor: pointer;
    }
</style>
