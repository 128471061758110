<template>
    <div>
        <b-card title="Pays" v-show="action=='list'">
            <b-button pill size="sm" class="mb-2" variant="success" @click="createCountry">
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>&nbsp;Ajouter
            </b-button>
            <b-input-group size="sm">
              <b-form-input id="filter-input"
                            v-model="filterValue"
                            placeholder="Nom ou Code du Pays" @change="filter = filterValue"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
            <b-table :items="countriesSort" :fields="countriesFields" head-variant="light" sticky-header="600px"
                      :busy="isLoading"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     tbody-tr-class="trclass"
            >
              <template #cell(id)="data">
                {{ data.value }}
              </template>
                <template #cell(name)="data">
                  <span class="text-capitalize">{{ data.value }}</span>
                </template>
                <template  #cell(countryInserts)="data">
                    <span class="limit-50 text-capitalize" :id="'span-'+data.item.id" v-b-tooltip.hover.bottom :title="data.value|countryToLine" >{{data.value|countryToLine}}</span>
                </template>
                <template #cell(code)="data">
                  <b-button size="sm" class="mb-2" v-b-tooltip.hover.bottomleft title="Editer" @click="editCountry(data.value)" >
                    <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button size="sm" class="mb-2" v-b-tooltip.hover.bottomleft title="Editer les produits"  @click="editCountryProduct(data.value)" >
                    <b-icon icon="stickies-fill" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button size="sm" class="mb-2" v-b-tooltip.hover.bottomleft title="Supprimer" @click="openConfirmDel(data.item)">
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
            </b-table>
        </b-card>
        <b-modal
            id="modal-prevent-delete-country"
            ref="modal"
            :title="'Confirmer la suppression de ' + deletePays.name"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <p>Êtes-vous sûr de vouloir supprimer le pays <b>{{ deletePays.name }}</b> ?<br>
              Cela peut avoir un impact sur vos données</p>
            <b-form-group
                invalid-feedback="La confirmation est requise"
                :state="confirmDeleteState"
            >
              <b-form-checkbox
                  id="confirm-input"
                  v-model="deletedConfirmed"
                  :state="confirmDeleteState"
                  required>Confirmation de suppression</b-form-checkbox>
            </b-form-group>
          </form>
        </b-modal>
        <EditCountry v-if="action=='edit'" :editId="editId" v-on:reset="resetForm"></EditCountry>
        <CreateCountry v-if="action=='create'" v-on:reset="resetForm"></CreateCountry>
        <EditProductsCountry v-if="action=='editProducts'" :editId="editId" v-on:reset="resetForm"></EditProductsCountry>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import EditCountry from './EditCountry.vue'
    import CreateCountry from './CreateCountry.vue'
    import EditProductsCountry from './EditProductsCountry.vue'
    import axios from "axios";

    export default {
        name: 'Country',
        components: {
            EditCountry,
            CreateCountry,
            EditProductsCountry
        },
        data() {
            return {
                deletePays: {name: '', id: null},
                deletedConfirmed: false,
                confirmDeleteState: null,
                filterValue: null,
                filter: null,
                filterOn: [],
                action: "list",
                editId: null,
                countriesFields: [
                    {
                      key: 'id',
                      label: 'Code'
                    },
                    {
                        key: 'name',
                        label: 'Pays'
                    },
                    {
                        key: 'countryInserts',
                        label: 'Pays dans les sites'
                    },
                    {
                      key: 'code',
                      label: 'Action'
                    }
                ]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach((va) => { arrName.push(va.name.toLowerCase()) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
        },
        methods: {
            openConfirmDel(countryToDel) {
              this.deletePays = countryToDel
              this.$bvModal.show('modal-prevent-delete-country');
            },
            checkFormValidity() {
              this.confirmDeleteState = this.deletedConfirmed
              return this.deletedConfirmed
            },
            resetModal() {
              this.deletedConfirmed = false
              this.confirmDeleteState = null
            },
            resetForm: function () {
                this.action = 'list'
            },
            editCountry: function (id) {
              this.editId = id
              this.action = 'edit'
            },
            editCountryProduct: function (id) {
              this.editId = id
              this.action = 'editProducts'
            },
            createCountry: function () {
              this.editId = null
              this.action = 'create'
            },
          handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
          },
          handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
              return
            }

            // Hide the modal manually
            this.$nextTick(() => {
              let self = this
              axios.delete(this.$ipService + '/api/Countries/' + this.deletePays.id
              ).then(function () {
                self.$store.dispatch('loadCountryInserts')
                self.$store.dispatch('loadGroupcountries')
                self.$store.dispatch('loadCountries')
                self.$store.dispatch('loadCountriesActives')
                self.$store.dispatch('loadGrouptypes')
                self.$store.dispatch('loadGrouplevels')
                self.$store.dispatch('loadWebsites')
                self.$store.dispatch('loadGroupdurations')
                self.$store.dispatch('loadGroupproducts')
                self.$emit('reset')
              }).catch(function (error) {
                console.log(error)
              }).finally(() => {
                self.$bvModal.hide('modal-prevent-delete-country')
                self.deletePays = {name: '', id: -1}
              })

            })
          }
        },
        computed: {
            isLoading: function () {
                return this.loaderList.length > 0
            },
            countriesSort: function () {
                if (this.countries == null) {
                    return []
                }
                let arrCountries = this.countries
                arrCountries.forEach(
                    (country) => {
                      country.name = country.name.toLowerCase();
                      country.code = country.id;
                    }
                )
                arrCountries.sort((a, b) => {
                  return a.name.localeCompare(b.name);
                })
                return arrCountries;
            },
            ...mapState(["countries", "countryInserts", "loaderList"])
        },
        mounted: function () {
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }
    .clickable {
        cursor: pointer
    }
    .limit-50 {
      display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 600px;
    }
    .thead-light {
      height: 15px;
    }
    tr.trclass {
      height: 15px;
    }
</style>

<style>
  .tooltip-inner {
    max-width: 600px!important;
  }
  .tooltip > .arrow {
    visibility: hidden!important;
    display: none!important;
  }
</style>
