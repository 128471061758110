import { render, staticRenderFns } from "./CreateCountryInsert.vue?vue&type=template&id=71f9a7a6&scoped=true"
import script from "./CreateCountryInsert.vue?vue&type=script&lang=js"
export * from "./CreateCountryInsert.vue?vue&type=script&lang=js"
import style0 from "./CreateCountryInsert.vue?vue&type=style&index=0&id=71f9a7a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f9a7a6",
  null
  
)

export default component.exports