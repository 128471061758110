<template>
    <b-card title="Editer les produits d'un pays">
        <b-form>
          <b-card>
          <b-container fluid>
            <b-row>
              <b-col cols="1">
                Code :
              </b-col>
              <b-col cols="2">
                {{country.id}}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="1">
                Nom :
              </b-col>
              <b-col cols="2">
                {{country.name}}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                &nbsp;
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="1">
                <b-form-checkbox id="allProductsFilter" @change="loadCountriesProduct();findProducts()"
                                 v-model="allProducts" value="1" unchecked-value="0" switch
                ></b-form-checkbox>
              </b-col>
              <b-col cols="2">
                Afficher les produits inactifs ou anciens produits
              </b-col>
            </b-row>
          </b-container>

          </b-card>

            <b-card sub-title="Ajouter un produit">
              <b-input-group size="sm">
                <b-form-input id="search-product"
                              v-model="searchValue"
                              placeholder="Code, Titre du Circuit, Pays Web" @change="findProducts" ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!searchValue"  @click="findProducts()">Filtrer</b-button>
                  <b-button :disabled="!searchValue" @click="searchValue = ''; productsToAdd = []">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-table :items="productsToAdd" :fields="countriesFields" head-variant="light" sticky-header="400px"
                       :busy="isLoading"
                       :tbody-tr-class="getRowClass">
                <template #cell(id)="data">
                  <b-icon-circle class="icon-span" @click="addProduct(data.item)" />
                </template>
                <template #cell(code)="data">
                  <a :href="data.item.urlPage" target="_blank">{{ data.value }}</a>
                </template>
                <template  #cell(countries)="data">
                  <span class="limit-20 text-capitalize" v-b-tooltip.hover.bottom :title="data.value|countryToLine" >{{data.value|countryToLine}}</span>
                </template>
                <template #cell(adapterId)="data">
                  {{ validWebsite[data.value].name }}
                </template>
              </b-table>
            </b-card>
            <b-card sub-title="Produits du pays">
                <b-input-group size="sm">
                <b-form-input id="filter-input"
                              v-model="filterValue"
                              placeholder="Code ou Titre du Circuit" @change="filter = filterValue"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                  <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
                </b-input-group-append>
                </b-input-group>
                <b-table :items="productsInCountry" :fields="countriesFields" head-variant="light" sticky-header="400px"
                         :busy="isBusy"
                         :filter="filter"
                         :filter-included-fields="filterOn"
                         :tbody-tr-class="getRowClass">
                  <template #cell(id)="data">
                    <b-icon-check-circle class="icon-span" @click="removeProduct(data.item)" />
                  </template>
                  <template #cell(code)="data">
                    <a :href="data.item.urlPage" target="_blank">{{ data.value }}</a>
                  </template>
                  <template  #cell(countries)="data">
                    <span class="limit-20 text-capitalize" v-b-tooltip.hover.bottom :title="data.value|countryToLine" >{{data.value|countryToLine}}</span>
                  </template>
                  <template #cell(adapterId)="data">
                    {{ validWebsite[data.value].name }}
                  </template>
                </b-table>
            </b-card>
            <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'

    export default {
        name: 'EditProductsCountry',
        components: {
        },
        data() {
            return {
                allProducts: '0',
                hasUpdate: false,
                searchValue: null,
                filter: null,
                filterOn: ['code', 'title'],
                isLoading: false,
                isFinding: false,
                filterValue: null,
                currentsProductsId: {},
                country: {},
                productsInCountry: [],
                productsToAdd: [],
                productsOpen: true,
                countriesFields: [
                  {
                    key: 'id',
                    label: 'Id'
                  },
                  {
                    key: 'code',
                    label: 'Code'
                  },
                  {
                    key: 'title',
                    label: 'Titre'
                  },
                  {
                    key: 'countries',
                    label: 'Pays'
                  },
                  {
                    key: 'countriesDebug',
                    label: 'Pays Web'
                  },
                  {
                    key: 'duration',
                    label: 'Durée'
                  },
                  {
                    key: 'adapterId',
                    label: 'Site'
                  }
                ]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
              console.log(value)
              if (value == null || value == undefined || value.length == 0) return ''

              let arrName = []
              value.forEach((va) => { arrName.push(va.name.toLowerCase()) });
              arrName.sort((a, b) => {
                return a.localeCompare(b);
              })
              return arrName.join(", ")
            },
        },
        props: {
            editId: String
        },
        event: ['reset', 'save'],
        methods: {
            getRowClass(item) {
              if (item.open == false) {
                return ['alert-danger', 'trclass']
              }
              return 'trclass'
            },
            loadCountriesProduct() {
                let self=this
                axios.get(this.productsUrl
                ).then(function (response) {
                      self.productsInCountry = response.data
                      self.productsInCountry.sort((a, b) => {
                      return a.code.localeCompare(b.code);
                    });
                      self.currentsProductsId = {}
                      self.productsInCountry.forEach((product) => {
                      self.currentsProductsId[product.id] = product
                    })
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    self.isLoading = false
                })
            },
            addProduct(product) {
                let self=this
                axios.put(this.$ipService + '/api/Countries/' + this.editId + '/addProduct/', {Id: product.id}
                ).then(function () {
                    self.productsInCountry.push(product)
                    self.productsInCountry.sort((a, b) => {
                      return a.code.localeCompare(b.code);
                    });
                    self.currentsProductsId[product.id] = product
                    self.productsToAdd = self.productsToAdd.filter((p) => {
                      return p.id !== product.id
                    })
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                  self.hasUpdate = true
                })
            },
            removeProduct(product) {
              let self=this
              axios.put(this.$ipService + '/api/Countries/' + this.editId + '/delProduct/', {Id: product.id}
              ).then(function () {
                self.productsInCountry = self.productsInCountry.filter((p) => {
                  return p.id !== product.id
                })
                self.productsInCountry.sort((a, b) => {
                  return a.code.localeCompare(b.code);
                });
                self.currentsProductsId[product.id] = undefined
                delete self.currentsProductsId[product.id]
                self.findProducts();
              }).catch(function (error) {
                console.log(error);
              }).finally(() => {
                self.hasUpdate = true
              })
            },
            resetform() {
                if (this.hasUpdate) {
                  this.$store.dispatch('loadCountryInserts')
                  this.$store.dispatch('loadGroupcountries')
                  this.$store.dispatch('loadCountries')
                  this.$store.dispatch('loadCountriesActives')
                  this.$store.dispatch('loadGrouptypes')
                  this.$store.dispatch('loadGrouplevels')
                  this.$store.dispatch('loadWebsites')
                  this.$store.dispatch('loadGroupdurations')
                  this.$store.dispatch('loadGroupproducts')
                }
                this.$emit('reset')
            },
            findProducts() {
                this.isFinding = true
                let self=this
                axios.get( this.productsSearchUrl
                ).then(function (response) {
                    let productsToAdd = response.data
                    self.productsToAdd = productsToAdd.filter((product) => {
                      return !self.currentsProductsId[product.id]
                    })
                    self.productsToAdd.sort((a, b) => {
                      return a.code.localeCompare(b.code);
                    });

                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    self.isFinding = false
                })
            }
        },
        computed: {
            isBusy() {
                return this.isLoading || this.isFinding
            },
            productsUrl() {
              if (this.allProducts == 1) {
                 return this.$ipService + '/api/products/byCountry/' + this.editId + '?date=' + new Date().getTime()
              }
              return this.$ipService + '/api/products/byCountry/' + this.editId + '?open=1&date=' + new Date().getTime()
            },
            productsSearchUrl() {
              if (this.allProducts == 1) {
                 return this.$ipService + '/api/products/byInfo/' + this.searchValue + '?date=' + new Date().getTime()
              }
              return this.$ipService + '/api/products/byInfo/' + this.searchValue + '?open=1&date=' + new Date().getTime()
            },
            ...mapState(["countries", "countryInserts", "currentUser", "validWebsite"])
        },
        mounted: function () {
            this.hasUpdate = false
            this.isLoading = true
            let self=this
            axios.get(this.$ipService + '/api/Countries/' + this.editId + '?date=' + new Date().getTime()
            ).then(function (response) {
                self.country = response.data
                self.loadCountriesProduct()
            }).catch(function (error) {
                self.isLoading = false
                console.log(error);
            }).finally(() => {

            })
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }

    tr.trclass {
      height: 15px;
    }
</style>
